body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background-color: #fafbfc;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.webim-loading {
    position: fixed;
    z-index: 3;
    background-color: #FAFAFA;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

}

.webim-rtc {
    position: absolute;
    /*width: 50%;*/
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #2A2A2A;
    /*padding: 16px 20px 17px 20px;*/
    /*border: 0 solid #ADB9C1;*/
    box-shadow: 0 0 10px #ADB9C1;
    max-height: 99%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.multi-webim-rtc {
    position: absolute;
    width: 7rem;
    top: 0;
    left: 0;
    z-index: 100;
    /*height: 16rem;*/
    background-color: #2A2A2A;
    /*padding: 16px 20px 17px 20px;*/
    /*border: 0 solid #ADB9C1;*/
    box-shadow: 0 0 10px #ADB9C1;
    max-height: 99%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.multi-webim-rtc .groupname {
    height: 36px;
}

.multi-webim-rtc .groupname img{
    width: 24px;
    height: 20px;
    margin: 8px 13px;
}

.multi-webim-rtc .istalking{
    position: absolute;
    right: 5px;
    bottom: 5px;
    width: 12px;
    height: 12px;
}

.istalking img {
    display: block;
    height: 12px;
    width: 12px;
}

.multi-webim-rtc .hide{
    display: none;
}

.video-box{
    width: 50%;
    height: 0;
    padding-bottom: 50%;
    background: yellow;
}
.video-duration{
    margin: 0;
    text-align: center;
    font-size: 12px;
    color: #fff;
}
.multi-webim-rtc .action-wrap {
    height: 100px;
    display: flex;
}
.action-wrap .tools{
    margin-top: 12px;
}
.action-wrap .tools img{
    width: 40px;
    height: 40px;
}
.tools p {
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    margin-top: 5px;
}
.action-wrap div:nth-child(1){
    margin-left: 3%;
}
.action-wrap div:nth-child(2){
    margin-left: 22%;
}
.action-wrap div:nth-child(3){
    margin-left: 10%;
}
.action-wrap div:nth-child(4){
    margin-left: 10%;
}
.multi-webim-rtc .time {
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    margin-bottom: 10px;
}


.multi-webim-rtc .default {
    height: 2.5rem;
    width: 100%;
    background-size:100% 100%;
    /*background: #303D4E;*/
    /*border: 1px solid #F2F4F6;*/
    border-radius: 2px;
    
}
.joining{
    background: url('./themes/img/video-bg2.png') no-repeat;
    background-size: cover;
}
.joining::after {
    content: '';
    /*background: url('./themes/img/video-loading@2x.png');*/
    width: 20%;
    height: 20%;
    background-size: cover;
    position: absolute;
    transform: translate(-50%, -50%);
    /*animation: turn 1s linear infinite;*/
    left: 40%;
    top: 40%;
}

@keyframes turn{
    0%{-webkit-transform:rotate(0deg);}
    25%{-webkit-transform:rotate(90deg);}
    50%{-webkit-transform:rotate(180deg);}
    75%{-webkit-transform:rotate(270deg);}
    100%{-webkit-transform:rotate(360deg);}
}

.multi-webim-rtc .user-name {
    height: 20px;
    width: 100%;
    background: rgba(35, 42, 45, 0.2);
    border-radius: 2px;
    position: absolute;
    bottom: 5px;
    padding-left: 10px;
}

.multi-webim-rtc .remote-ajust{
    bottom: 5px;
}

.multi-webim-rtc .user-name span{
    height: 20px;
    width: 28px;
    font-size: 14px;
    line-height: 20px;
    color: #FFF;
    letter-spacing: 2px;
    font-weight: 400;
}

.multi-webim-rtc .user-name i{
    color: #FFF;
    float: right;
    font-size: 20px;
    margin-left: 8px;
    line-height: 20px;
    background-color: rgba(0, 186, 110, 1);
    border-radius: 50%;
    cursor: pointer;
} 

.multi-webim-rtc .user-name i.camera{
    background-color: rgba(251, 0, 22, 0.6);
}

.multi-webim-rtc .user-name i.camera-shut{
    background-color: rgba(251, 0, 22, 1);
}

.multi-webim-rtc .user-name i.sound{
    background-color: rgba(0, 186, 110, 0.6);
}

.multi-webim-rtc .user-name i.sound-shut{
    background-color: rgba(0, 186, 110, 1);
}

.multi-webim-rtc .video-divisor {
    height: 13px;
}

.multi-webim-rtc .tools-divisor {
    height: 30px;
}

.multi-webim-rtc .tools {
    height: 32px;
    text-align: center;
}


/* .multi-webim-rtc .tools i.i-hover{
    color: rgba(83, 90, 93, 0.4);
}

.multi-webim-rtc .tools i.i-act{
    color: rgba(83, 90, 93, 1);
} */

.multi-webim-rtc .tools .hangup{
    height: 26px;
    width: 70px;
    background-color: #FF3B30;
    border-radius: 2px;
    margin: 3px auto;
    font-size: 12px;
    color: white;
    line-height: 26px;
    letter-spacing: 1px;
    font-weight: 300;
    cursor: pointer;
}

